var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-md-4 mb-12", attrs: { justify: "center" } },
        [_c("h1", [_vm._v("Consulter et modifier les tables du groupe")])]
      ),
      _c(
        "v-row",
        { staticClass: "py-md-4", attrs: { justify: "center" } },
        [
          _c(
            "v-card",
            { attrs: { "max-width": "750", outlined: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-md-4 py-4",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " Nous avons besoin de vous authentifier pour vous donner l'accès à l'application. "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-md-4 pb-4",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-flex justify-center px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: !_vm.ready || _vm.loading,
                                loading: _vm.loading,
                              },
                              on: { click: _vm.login },
                            },
                            [
                              _c("div", { staticClass: "capitalize" }, [
                                _vm._v("Se connecter avec mon compte Avec"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400px" },
          model: {
            value: _vm.showDialogUnauthorized,
            callback: function ($$v) {
              _vm.showDialogUnauthorized = $$v
            },
            expression: "showDialogUnauthorized",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Accès limité"),
                ]),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Vous n'êtes pas autorisé à utiliser cette application pour l'instant."
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogUnauthorized = false
                        },
                      },
                    },
                    [_vm._v("Quitter")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }